import React, { useState, useEffect } from 'react';
import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import './RoundGraph.scss';
import gaussRound from '../../utils/helpers/gaussRound';

const RoundGraph = ({ data, custom }) => {
  window.Highcharts = Highcharts;
  const [graphData, setGraphData] = useState([]);

  const pieColor = procent => {
    return color('#03b5b1')
      .brighten(+0.5 / Number.parseFloat(procent))
      .get();
  };

  useEffect(() => {
    let sum = 0;
    let mas = [];
    for (let i = 0; i < data.length; i++) {
      sum += Number.parseFloat(data[i]['par2']);
    }
    for (let i in data) {
      const elem = data[i];
      const obj = Object({
        name: elem['par1'],
        y: gaussRound(Number.parseFloat(elem['par2']), 2),
        color: pieColor((100 * Number.parseFloat(elem['par2'])) / sum),
        sumdat: gaussRound(elem['par3'], 2),
      });
      mas.push(obj);
    }

    setGraphData(mas);
    return () => {
      setGraphData([]);
    };
  }, [data]);

  let graphOptions = {
    credits: false,
    title: null,
    tooltip: {
      headerFormat: null,
      pointFormat: custom
        ? custom
        : '<b>{point.name} ({point.sumdat} % годовых)</b><br>{point.y} р ({point.percentage:.1f} %)',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        shadow: false,
        dataLabels: {
          enabled: true,
          distance: -30,
          color: 'white',
          format: '<b>{point.percentage:.1f} %</b>',
          style: {
            textOutline: '',
          },
          filter: {
            property: 'percentage',
            operator: '>',
            value: 5,
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        name: '',
        data: graphData,
      },
    ],
  };

  return (
    <div className="round-graph">
      {graphData && (
        <HighchartsReact highcharts={Highcharts} options={graphOptions} />
      )}
    </div>
  );
};

export default RoundGraph;
