import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';

import store from '../../redux/store';
import { pageActions, dataActions } from '../../redux/actions';
import { scrollSmooth } from '../../utils/helpers';
import { InformViewer, Table } from '../../components';

import './QuarterlyStatement.scss';

const QuarterlyStatement = ({
  dbData,
  isClick,
  currentPage,
  history,
  location,
  setPage,
  isLoading,
  setIsClick,
  setIsLoading,
}) => {
  const InfoRef = useRef(null);
  const trig = [
    {
      name: 'p1',
      ref: useRef(null),
    },
    {
      name: 'p2',
      ref: useRef(null),
    },
    {
      name: 'p3',
      ref: useRef(null),
    },
    {
      name: 'p4',
      ref: useRef(null),
    },
    {
      name: 'p5',
      ref: useRef(null),
    },
  ];

  const buttonList = () => {
    setIsClick(true);
  };
  useEffect(() => {
    window.addEventListener('popstate', buttonList, false);
    return () => {
      window.removeEventListener('popstate', buttonList, false);
    };
  });

  useEffect(() => {
    if (isClick) {
      switch (location.pathname) {
        case '/quarterly/p1':
          if (trig[0].ref.current) {
            scrollSmooth(trig[0].ref.current).then(() => {
              setIsClick(false);
            });
          }
          break;
        case '/quarterly/p2':
          if (trig[1].ref.current) {
            scrollSmooth(trig[1].ref.current).then(() => {
              setIsClick(false);
            });
          }
          break;
        case '/quarterly/p3':
          if (trig[2].ref.current) {
            scrollSmooth(trig[2].ref.current).then(() => {
              setIsClick(false);
            });
          }
          break;
        case '/quarterly/p4':
          if (trig[3].ref.current) {
            scrollSmooth(trig[3].ref.current).then(() => {
              setIsClick(false);
            });
          }
          break;
        case '/quarterly/p5':
          if (trig[4].ref.current) {
            scrollSmooth(trig[4].ref.current).then(() => {
              setIsClick(false);
            });
          }
          break;
        default:
          break;
      }
    }
  });

  useEffect(() => {
    store.dispatch(dataActions.fetchQuarterlyData());
  }, []);

  const onEnterViewport = trig => {
    if (isClick) return;
    let newLocation = `/quarterly/${trig.name}`;
    if (location.pathname !== newLocation) {
      history.push(newLocation);
    }
  };

  const onExitViewport = trig => {
    // console.log('exited', trig);
  };

  return (
    <InformViewer refCont={InfoRef} onScroll={() => {}}>
      {dbData && (
        <div>
          <h1>Квартальная выписка</h1>
          <section ref={trig[0].ref} className="inform-viewer__section">
            <Waypoint
              onEnter={() => onEnterViewport(trig[0])}
              onLeave={() => onExitViewport(trig[0])}
            />
            <h2>Сводный отчет</h2>
            <Table
              header={dbData.SummaryData.header}
              body={dbData.SummaryData.body}
              footer={dbData.SummaryData.footer}
              isFixed
            />
          </section>
          <section ref={trig[1].ref} className="inform-viewer__section">
            <Waypoint
              onEnter={() => onEnterViewport(trig[1])}
              onLeave={() => onExitViewport(trig[1])}
            />
            <Table
              key="1"
              title="Внебиржевой портфель"
              classNames="info-table--summary rtb"
              header={dbData.LightHeadedBriefcase.header}
              body={dbData.LightHeadedBriefcase.body}
              footer={dbData.LightHeadedBriefcase.footer}
            />
          </section>
          <section ref={trig[2].ref} className="inform-viewer__section">
            <Waypoint
              onEnter={() => onEnterViewport(trig[2])}
              onLeave={() => onExitViewport(trig[2])}
            />
            <Table
              key="2"
              title="Облигационый портфель"
              classNames="info-table--summary rtb"
              header={dbData.BondPortfolio.header}
              body={dbData.BondPortfolio.body}
              footer={dbData.BondPortfolio.footer}
            />
          </section>
          <section ref={trig[3].ref} className="inform-viewer__section">
            <Waypoint
              onEnter={() => onEnterViewport(trig[3])}
              onLeave={() => onExitViewport(trig[3])}
            />
            <Table
              key="3"
              title="Портфель акция и валюты"
              classNames="info-table--summary rtb"
              header={dbData.StockPortfolio.header}
              body={dbData.StockPortfolio.body}
              footer={dbData.StockPortfolio.footer}
            />
          </section>
          <section ref={trig[4].ref} className="inform-viewer__section">
            <Waypoint
              onEnter={() => onEnterViewport(trig[4])}
              onLeave={() => onExitViewport(trig[4])}
            />
            <Table
              key="4"
              title="Портфель фьючерсы и опционы"
              classNames="info-table--summary rtb"
              header={dbData.FuturesPortfolio.header}
              body={dbData.FuturesPortfolio.body}
              footer={dbData.FuturesPortfolio.footer}
            />
          </section>
        </div>
      )}
    </InformViewer>
  );
};

export default connect(
  ({ page, data }) => ({
    currentPage: page.data.currentPage,
    isClick: page.isClick,
    dbData: data.querterlyData,
    isLoading: data.isLoading,
  }),
  { ...pageActions, ...dataActions }
)(QuarterlyStatement);
