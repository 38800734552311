import axios from 'axios';
import { Cookies } from 'react-cookie';

axios.defaults.baseURL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : `https://api.mainserver.localdev`;
let co = new Cookies();
axios.defaults.headers.common['X-TOKEN'] = co.get('token');

window.axios = axios;

export default axios;
