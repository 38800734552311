import React from 'react';
import cn from 'classnames';
import './Table.scss';
import { convertValue } from '../../utils/helpers';

const getValue = (item, type = 'th', itemKey = 0) => {
  if (typeof item === 'object' && item !== null) {
    switch (type) {
      case 'th':
        return (
          <th key={itemKey} className={cn(item.class)} colSpan={item.colSpan}>
            {convertValue(item.value, item.type, '')}
          </th>
        );
      case 'td':
        return (
          <td key={itemKey} className={cn(item.class)} colSpan={item.colSpan}>
            {convertValue(item.value, item.type)}
          </td>
        );
      default:
        return null;
    }
  } else {
    switch (type) {
      case 'th':
        return <th key={itemKey}>{convertValue(item, null, '')}</th>;
      case 'td':
        return <td key={itemKey}>{convertValue(item)}</td>;
      default:
        return null;
    }
  }
};
const Table = ({ currentRef, classNames, header, body, footer }) => {
  return (
    <table ref={currentRef} className={cn('info-table', classNames)}>
      {header && (
        <thead>
          {header.map((row, rowKey) => {
            if (row.data !== undefined) {
              return (
                <tr key={rowKey} className={cn(row.class)}>
                  {row.data.map((item, itemKey) =>
                    getValue(item, 'th', itemKey)
                  )}
                </tr>
              );
            } else {
              return (
                <tr key={rowKey}>
                  {row.map((item, itemKey) => getValue(item, 'th', itemKey))}
                </tr>
              );
            }
          })}
        </thead>
      )}
      {body && (
        <tbody>
          {body.map((row, rowKey) => {
            if (row.data !== undefined) {
              return (
                <tr key={rowKey} className={cn(row.class)}>
                  {row.data.map((item, itemKey) =>
                    getValue(item, 'td', itemKey)
                  )}
                </tr>
              );
            } else {
              return (
                <tr key={rowKey}>
                  {row.map((item, itemKey) => getValue(item, 'td', itemKey))}
                </tr>
              );
            }
          })}
        </tbody>
      )}
      {footer && (
        <tfoot>
          {footer.map((row, rowKey) => (
            <tr key={rowKey} className={cn(row.class)}>
              {row.map((item, itemKey) => getValue(item, 'td', itemKey))}
            </tr>
          ))}
        </tfoot>
      )}
    </table>
  );
};

export default Table;
