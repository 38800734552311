import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import './LaneLoading.scss';
import { dataActions } from '../../redux/actions';

const LaneLoading = ({ procent, isLoading, setIsLoading }) => {
  const startDelay = 10;
  const loadingBlock = useRef(null);
  const loader = useRef(null);
  const [transition, setTransition] = useState(1);
  const [loadingProcent, setLoadingProcent] = useState(20);
  const [delay, setDelay] = useState(startDelay);
  const [isLoaderWork, setIsLoaderWork] = useState(false);

  // Если isLoading false То нечего не делаем

  useEffect(() => {
    let LoaderTimer;
    if (isLoading === false) {
      if (isLoaderWork === true) {
        // Загрузка завершена показываем 100%
        clearTimeout(LoaderTimer);
        setTransition(0.5);
        setLoadingProcent(100);
        setTimeout(() => {
          // Закрываем лоадер
          clearTimeout(LoaderTimer);
          setIsLoaderWork(false);
          setLoadingProcent(0);
          setTransition(1);
          setDelay(startDelay);
        }, 1500);
      }
    } else {
      // Запускаем лоадер
      !isLoaderWork && setIsLoaderWork(true); // Теперь говорим что loader запущени
      if (loadingProcent < 95) {
        LoaderTimer = setTimeout(() => {
          if (isLoaderWork) {
            if (loadingProcent < 95) {
              setLoadingProcent(loadingProcent + 1);
            }
            if (loadingProcent >= 50 && loadingProcent <= 95) {
              setDelay(delay + 10);
            }
          }
        }, delay);
      }
    }

    return () => {
      clearTimeout(LoaderTimer);
    };
  }, [isLoading, isLoaderWork, loadingProcent, delay]);

  return (
    <div ref={loadingBlock} className="lane-loading">
      <div
        ref={loader}
        style={{
          width: `${loadingProcent}%`,
          transition: loadingProcent === 0 ? '0s' : `${transition}s`,
        }}
        className={cn('lane-loading__loader', {
          'lane-loading__loader--hide': !isLoading && !isLoaderWork,
        })}
      ></div>
    </div>
  );
};

export default connect(
  ({ data }) => ({ isLoading: data.isLoading }),
  dataActions
)(LaneLoading);
