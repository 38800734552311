import { userApi } from '../../utils/api';

const Actions = {
  setUserData: data => ({
    type: 'USER:SET_DATA',
    payload: data,
  }),
  setIsAuth: isAuth => ({
    type: 'USER:SET_IS_AUTH',
    payload: isAuth,
  }),
  fetchUserData: () => dispatch => {
    userApi
      .getMe()
      .then(({ data }) => {
        dispatch(Actions.setUserData(data));
        dispatch(Actions.setIsAuth(true));
      })
      .catch(err => {
        if (err.response.status === 403) {
          console.log('Error USER:SET_DATA');
          dispatch(Actions.setIsAuth(false));
        }
      });
  },
};

export default Actions;
