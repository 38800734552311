const initialState = {
  data: {
    currentPage: 'periud/p1',
  },
  isClick: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'PAGE:SET_PAGE':
      return {
        ...state,
        data: payload,
      };
    case 'PAGE:SET_IS_CLICK':
      return {
        ...state,
        isClick: payload,
      };
    default:
      return state;
  }
};
