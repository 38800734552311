import React, { useState } from 'react';
import cn from 'classnames';

import './Switch.scss';
const Switch = ({ children, title }) => {
  const [activeSwitch, setActiveSwitch] = useState(children[0].props.id);

  const onSwitch = id => {
    setActiveSwitch(id);
  };
  return (
    <div className="switch">
      <div className="switch__header">
        <h3 className="switch__header-title">{title}</h3>
        <div className="switch__header-buttons">
          {children.map((child, key) => {
            return (
              <div
                className={cn('switch__button', [
                  { 'switch__button--active': child.props.id === activeSwitch },
                ])}
                key={key}
                onClick={() => onSwitch(child.props.id)}
              >
                {child.props.icon}
              </div>
            );
          })}
        </div>
      </div>
      <div className="switch__content">
        {children.map(child => {
          if (child.props.id === activeSwitch) return child.props.children;
          else return null;
        })}
      </div>
    </div>
  );
};

export default Switch;
