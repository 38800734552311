import moment from 'moment';

import gaussRound from './gaussRound';

export default (value, type = null, defaultValue = '—') => {
  if (value === null) return defaultValue;
  if (type === null) {
    // Определяем автоматически
    switch (typeof value) {
      case 'number':
        return gaussRound(value, 2).toLocaleString('ru');

      default:
        return value;
    }
  } else {
    switch (type) {
      case 'procent':
        return gaussRound(value * 100, 1).toLocaleString('ru') + '%';
      case 'date':
        return moment(value).format('DD.MM.YYYY');
      default:
        break;
    }
  }
};
