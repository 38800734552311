import React from 'react';
import { Cookies } from 'react-cookie';

import './UserInform.scss';

const UserInform = ({ userName, startDate }) => {
  const logOut = () => {
    const cookie = new Cookies();
    cookie.remove('token', { path: '/', domain: '.uscapital.ru' });
    window.location.reload();
  };
  return (
    <div className="user">
      <div className="user__name">
        {userName} <br /> <small>Дата размещения: {startDate}</small>
      </div>
      <button
        href="/logout"
        className="logout-button"
        onClick={() => logOut()}
      />
    </div>
  );
};

export default UserInform;
