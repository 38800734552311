import React, { useRef, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';
import { DatePicker } from 'antd';

import store from '../../redux/store';
import { dataActions } from '../../redux/actions';
import { dateInputMask } from '../../utils/helpers';

import './Calendar.scss';

const { RangePicker } = DatePicker;

const Calendar = ({ isLoading, userData, history }) => {
  const ref = useRef(null);
  const [date, setDate] = useState(null);
  const [defaultDate, setDefaultDate] = useState([]);
  const [hideRangePicker, setHideRangePicker] = useState(false);

  const currentPath = history.location.pathname.split('/')[1];
  useEffect(() => {
    if (currentPath === 'periud') {
      setHideRangePicker(false);
      defaultDate[0] &&
        defaultDate[1] &&
        store.dispatch(
          dataActions.fetchPeriudData(
            defaultDate[0].format('DD.MM.YYYY'),
            defaultDate[1].format('DD.MM.YYYY')
          )
        );
    } else {
      setHideRangePicker(true);
    }
  }, [currentPath, defaultDate]);

  useEffect(() => {
    userData && setDate(userData.userData.date_open);
    const elements = document.querySelectorAll('.ant-picker-input > input');
    for (let elem of elements) {
      dateInputMask(elem);
    }
    const dateData = localStorage.getItem('selected_date')?.split('&');
    const dateOpen = userData
      ? moment(userData.userData.date_open, 'YYYY-MM-DD')
      : null;
    if (dateData && dateOpen) {
      if (dateData[0] !== '' && dateData[1] !== '') {
        const startDate = moment(dateData[0], 'DD.MM.YYYY');
        const endDate = moment(dateData[1], 'DD.MM.YYYY');
        if (!startDate.isSameOrAfter(dateOpen)) {
          localStorage.setItem(
            'selected_date',
            `${dateOpen.format('DD.MM.YYYY')}&${dateData[1]}`
          );
        }
        setDefaultDate([
          startDate.isSameOrAfter(dateOpen) ? startDate : dateOpen,
          endDate.isSameOrAfter(dateOpen) ? endDate : dateOpen,
        ]);
      }
    }
  }, [userData]);

  const onChange = (value, dateString) => {
    if (value === null) {
      setDefaultDate([undefined, undefined]);
      return;
    }
    localStorage.setItem('selected_date', `${dateString[0]}&${dateString[1]}`);
    setDefaultDate([
      moment(dateString[0], 'DD.MM.YYYY'),
      moment(dateString[1], 'DD.MM.YYYY'),
    ]);
  };
  const disabledDate = current => {
    const startWorkDate = moment(date);
    return !startWorkDate.isSameOrBefore(current);
  };

  return (
    <div className="calendar">
      <RangePicker
        ref={ref}
        value={defaultDate}
        className="Hello"
        disabled={hideRangePicker || isLoading}
        size="large"
        format="DD.MM.YYYY"
        disabledDate={disabledDate}
        onChange={onChange}
      />
    </div>
  );
};

export default connect(({ data, user }) => ({
  isLoading: data.isLoading,
  userData: user.data,
}))(withRouter(Calendar));
