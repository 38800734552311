import React, { useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import { axios } from '../../core';
import downloadImg from '../../assets/img/download.svg';

import './DocumentBtn.scss';

const DocumentBtn = ({ currentPage }) => {
  const [isDowload, setIsDowload] = useState(false);
  const [downloadText, setDownloadText] = useState('Документ формируется');

  const startDownload = () => {
    const mainPage = window.location.pathname.split('/')[1];
    const dateData = localStorage.getItem('selected_date')?.split('&');
    if (isDowload || dateData === undefined) return;

    setIsDowload(true);
    const url =
      mainPage === 'quarterly'
        ? `/document/quarterly`
        : `/document/periud?d1=${dateData[0]}&d2=${dateData[1]}`;
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob', // important
    })
      .then(response => {
        const fileNameHeader = 'x-document-filename';
        console.log(response);
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined ? 'document.docx' : suggestedFileName;
        setIsDowload(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', effectiveFileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        setTimeout(() => {
          setIsDowload(false);
          setDownloadText('Документ формируется');
        }, 3000);
        setDownloadText('Ошибка! Попробуйте позже');
        console.log('error');
      });
  };

  return (
    <div
      className="document_btn"
      title="Скачать документ"
      onClick={startDownload}
    >
      <div className="btn_block">
        <div
          className={cn('document_btn__img', {
            'document_btn__img--down': isDowload,
          })}
        >
          <img src={downloadImg} alt="download button" />
        </div>
        <div
          className={cn('document_btn__download', {
            'document_btn__download--show': isDowload,
          })}
        >
          <span>{downloadText}</span>
        </div>
      </div>
    </div>
  );
};

// export default ;

export default connect(({ page }) => ({
  currentPage: page.data.currentPage,
}))(DocumentBtn);
