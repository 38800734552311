const initialState = {
  periudData: null,
  querterlyData: null,
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'DATA:SET_PERIUD_DATA':
      return {
        ...state,
        periudData: payload.data,
      };
    case 'DATA:SET_QUARTERLY_DATA':
      return {
        ...state,
        querterlyData: payload.data,
      };
    case 'DATA:SET_IS_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return state;
  }
};
