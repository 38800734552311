import React from 'react';

import logoImg from '../../assets/img/logo.png';
import { UserInform } from '../../containers';

import './Header.scss';

const Header = () => {
  return (
    <header>
      <div className="header">
        <div className="header__logo">
          <a href="http://uscpital.ru/we/">
            <img src={logoImg} alt="Site logo" />
          </a>
        </div>
        <UserInform />
      </div>
    </header>
  );
};

export default Header;
