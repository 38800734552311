const Actions = {
  setPage: data => ({
    type: 'PAGE:SET_PAGE',
    payload: data,
  }),
  setIsClick: data => ({
    type: 'PAGE:SET_IS_CLICK',
    payload: data,
  }),
};

export default Actions;
