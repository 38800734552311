export default elem => {
  elem.addEventListener('keyup', e => {
    // if (e.keyCode < 47 || e.keyCode > 57) {
    //   e.preventDefault();
    // }
    elem.value = elem.value.replace(/[^0-9.]/g, '');

    var len = elem.value.length;
    if (len !== 1 || len !== 3) {
      if (e.keyCode === 47) {
        e.preventDefault();
      }
    }
    if (len === 2) {
      if (e.keyCode !== 8 && e.keyCode !== 46) {
        elem.value = elem.value + '.';
      }
    }

    if (len === 5) {
      if (e.keyCode !== 8 && e.keyCode !== 46) {
        elem.value = elem.value + '.';
      }
    }
  });
};
