import React from 'react';
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import './Navigation.scss';
import { pageActions } from '../../redux/actions';

const Navigation = ({
  currentPage,
  location,
  history,
  setPage,
  setIsClick,
}) => {
  return (
    <div className="navigation">
      <Menu
        style={{ width: 325 }}
        defaultOpenKeys={['sub1']}
        mode="inline"
        selectedKeys={[location.pathname]}
        onSelect={() => setIsClick(true)}
      >
        <Menu.ItemGroup key="1" title="Выписка за период">
          <Menu.Item key="/periud/p1">
            <Link to="/periud/p1">Сводные данные</Link>
          </Menu.Item>
          <Menu.Item key="/periud/p2">
            <Link to="/periud/p2">Текущие инвестиции</Link>
          </Menu.Item>
          <Menu.Item key="/periud/p3">
            <Link to="/periud/p3">Прибыль за период</Link>
          </Menu.Item>
          <Menu.Item key="/periud/p4">
            <Link to="/periud/p4">Ближайшие выплаты</Link>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key="2" title="Квартальная выписка">
          <Menu.Item key="/quarterly/p1">
            <Link to="/quarterly/p1">Сводный отчет</Link>
          </Menu.Item>
          <Menu.Item key="/quarterly/p2">
            <Link to="/quarterly/p2">Внебиржевой портфель</Link>
          </Menu.Item>
          <Menu.Item key="/quarterly/p3">
            <Link to="/quarterly/p3">Облигационный портфель</Link>
          </Menu.Item>
          <Menu.Item key="/quarterly/p4">
            <Link to="/quarterly/p4">Портфель акций и валюты</Link>
          </Menu.Item>
          <Menu.Item key="/quarterly/p5">
            <Link to="/quarterly/p5">Портфель фьючерсы и опционы</Link>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    </div>
  );
};

export default connect(
  ({ page }) => ({ currentPage: page.data.currentPage }),
  pageActions
)(withRouter(Navigation));
