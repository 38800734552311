import { dataApi } from '../../utils/api';

const Actions = {
  setPeriudData: data => ({
    type: 'DATA:SET_PERIUD_DATA',
    payload: data,
  }),
  setQuarterlyData: data => ({
    type: 'DATA:SET_QUARTERLY_DATA',
    payload: data,
  }),
  setIsLoading: isLoading => ({
    type: 'DATA:SET_IS_LOADING',
    payload: isLoading,
  }),
  fetchPeriudData: (startDate, endDate) => dispatch => {
    dispatch(Actions.setIsLoading(true));
    dataApi
      .getPeriudData(startDate, endDate)
      .then(({ data }) => {
        dispatch(Actions.setPeriudData(data));
      })
      .catch(err => {
        console.log(err);
        if (err.response.status === 403) {
          console.log('Error DATA:SET_DATA');
        }
      })
      .finally(() => {
        dispatch(Actions.setIsLoading(false));
      });
  },
  fetchQuarterlyData: () => dispatch => {
    dispatch(Actions.setIsLoading(true));
    dataApi
      .getQuarterlyData()
      .then(({ data }) => {
        dispatch(Actions.setQuarterlyData(data));
      })
      .catch(err => {
        console.log(err);
        if (err.response.status === 403) {
          console.log('Error DATA:SET_DATA');
        }
      })
      .finally(() => {
        dispatch(Actions.setIsLoading(false));
      });
  },
};

export default Actions;
