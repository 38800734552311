import React, { useRef, useEffect } from 'react';

import Table from './Table';

const filtredItem = (index, fixedIndex, isContain = true) => {
  if (isContain)
    return Array.isArray(fixedIndex)
      ? fixedIndex.includes(index)
      : fixedIndex === index;
  else
    return Array.isArray(fixedIndex)
      ? !fixedIndex.includes(index)
      : fixedIndex !== index;
};
const prepareFixedData = ({ header, body, footer, options }) => {
  const fixedColumnIndex = options.fixedColumn;
  let fixedColumTable = {
    header: [],
    body: [],
    footer: [],
  };
  let fixedContentTable = {
    header: [],
    body: [],
    footer: [],
  };

  header.map((e, index) => {
    fixedColumTable.header.push(
      e.filter((value, index) => filtredItem(index, fixedColumnIndex))
    );
    fixedContentTable.header.push(
      e.filter((value, index) => filtredItem(index, fixedColumnIndex, false))
    );
    return e;
  });
  body.map((e, index) => {
    if (e.data !== undefined) {
      fixedColumTable.body.push({
        class: e.class,
        data: e.data.filter((value, index) =>
          filtredItem(index, fixedColumnIndex)
        ),
      });
    } else {
      fixedColumTable.body.push(
        e.filter((value, index) => filtredItem(index, fixedColumnIndex))
      );
    }
    if (e.data !== undefined) {
      fixedContentTable.body.push({
        class: e.class,
        data: e.data.filter((value, index) =>
          filtredItem(index, fixedColumnIndex, false)
        ),
      });
    } else {
      fixedContentTable.body.push(
        e.filter((value, index) => filtredItem(index, fixedColumnIndex, false))
      );
    }
    return e;
  });
  footer.map((e, index) => {
    fixedColumTable.footer.push(
      e.filter((value, index) => filtredItem(index, fixedColumnIndex))
    );
    fixedContentTable.footer.push(
      e.filter((value, index) => filtredItem(index, fixedColumnIndex, false))
    );
    return e;
  });

  return [fixedColumTable, fixedContentTable];
};

const lineAlign = (columnTable, contentTable) => {
  let columnRows = columnTable.getElementsByTagName('tr');
  let contentRows = contentTable.getElementsByTagName('tr');
  for (var i = 0; i < columnRows.length; i++) {
    const hColumn = columnRows[i].offsetHeight;
    const cColumn = contentRows[i].offsetHeight;
    if (hColumn > cColumn) {
      contentRows[i].style.height = `${hColumn}px`;
    } else {
      columnRows[i].style.height = `${cColumn}px`;
    }
  }
};

const FixedTable = ({ header, body, footer, options }) => {
  const columnTableRef = useRef(null);
  const contentTableRef = useRef(null);
  const [fixedColumTable, fixedContentTable] = prepareFixedData({
    header,
    body,
    footer,
    options,
  });

  useEffect(() => {
    lineAlign(columnTableRef.current, contentTableRef.current);
  });

  return (
    <div className="fixed-table">
      <div className="fixed-table__fixed-column">
        <Table
          currentRef={columnTableRef}
          header={fixedColumTable.header}
          body={fixedColumTable.body}
          footer={fixedColumTable.footer}
        />
      </div>
      <div className="fixed-table__fixed-content">
        <Table
          currentRef={contentTableRef}
          header={fixedContentTable.header}
          body={fixedContentTable.body}
          footer={fixedContentTable.footer}
        />
      </div>
    </div>
  );
};

export default FixedTable;
