import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { UserInform as UserInformBase } from '../components';
import { shortInvetorName } from '../utils/helpers';

const UserInform = ({ currentDialogId, dialogs, user }) => {
  if (!user) {
    return null;
  }
  const userName =
    user.type_invest === '1'
      ? shortInvetorName(user.userData.userName)
      : user.userData.userName;
  const dateOpen = moment(user.userData.date_open, 'YYYY-MM-DD').format(
    'DD.MM.YY'
  );
  return <UserInformBase userName={userName} startDate={dateOpen} />;
};

export default connect(({ user }) => ({
  user: user.data,
}))(UserInform);
