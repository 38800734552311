import React from 'react';

import './NullTableData.scss';

const NullTableData = () => {
  return (
    <div className="null-table-data">
      <p className="null-table-data__text">Данных нет</p>
    </div>
  );
};

export default NullTableData;
