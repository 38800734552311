import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import './ChartGraph.scss';

const ChartGraph = ({ data }) => {
  window.Highcharts = Highcharts;

  let graphOptions = {
    chart: {
      type: 'column',
      inverted: true,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: data.category,
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: '',
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: '#fff',
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ₽<br/>',
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    series: data.data,
  };

  return (
    <div className="chart-graph">
      {data && (
        <HighchartsReact highcharts={Highcharts} options={graphOptions} />
      )}
    </div>
  );
};

export default ChartGraph;
