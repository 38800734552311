import React from 'react';
// import cn from 'classnames';

import './Table.scss';
import NullTableData from '../NullTableData';
import Table from './Table';
import FixedTable from './FixedTable';

const BaseTable = ({
  classNames,
  title,
  subtitle,
  header,
  body,
  footer,
  isFixed,
  options,
}) => {
  return (
    <div className="info">
      {title && <h2>{title}</h2>}
      {subtitle && <h3>{subtitle}</h3>}
      {body && body.length === 0 ? (
        <NullTableData />
      ) : !isFixed ? (
        <Table
          classNames={classNames}
          header={header}
          body={body}
          footer={footer}
        />
      ) : (
        <FixedTable
          header={header}
          body={body}
          footer={footer}
          options={{ fixedColumn: 0 }}
        />
      )}
    </div>
  );
};

export default BaseTable;
