import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import { CookiesProvider } from 'react-cookie';

import 'antd/dist/antd.css';
import './styles/index.scss';

import store from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <ConfigProvider locale={ruRU}>
        <Router>
          <App />
        </Router>
      </ConfigProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root')
);
