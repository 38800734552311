import React, { useRef, useEffect } from 'react';
import { Row, Col, Tabs } from 'antd';
import Icon from '@ant-design/icons';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';

import { ReactComponent as tableSvg } from '../../assets/img/table.svg';
import { ReactComponent as graphSvg } from '../../assets/img/graph.svg';

import { pageActions, dataActions } from '../../redux/actions';
import { scrollSmooth } from '../../utils/helpers';
import {
  InformViewer,
  RoundGraph,
  ChartGraph,
  Switch,
  SwitchContent,
  Table,
} from '../../components';

import './PeriudExtract.scss';

const { TabPane } = Tabs;

const PeriudExtract = ({
  isClick,
  currentPage,
  history,
  location,
  setPage,
  isLoading,
  setIsClick,
  setIsLoading,
  dbData,
}) => {
  const InfoRef = useRef(null);
  const trig = [
    {
      name: 'p1',
      ref: useRef(null),
    },
    {
      name: 'p2',
      ref: useRef(null),
    },
    {
      name: 'p3',
      ref: useRef(null),
    },
    {
      name: 'p4',
      ref: useRef(null),
    },
  ];

  useEffect(() => {
    if (isClick) {
      switch (location.pathname) {
        case '/periud/p1':
          if (trig[0].ref.current) {
            scrollSmooth(trig[0].ref.current).then(() => {
              setIsClick(false);
            });
          }
          break;
        case '/periud/p2':
          if (trig[1].ref.current) {
            scrollSmooth(trig[1].ref.current).then(() => {
              setIsClick(false);
            });
          }
          break;
        case '/periud/p3':
          if (trig[2].ref.current) {
            scrollSmooth(trig[2].ref.current).then(() => {
              setIsClick(false);
            });
          }
          break;
        case '/periud/p4':
          if (trig[3].ref.current) {
            scrollSmooth(trig[3].ref.current).then(() => {
              setIsClick(false);
            });
          }
          break;
        default:
          break;
      }
    }
  });

  useEffect(() => {
    window.addEventListener('popstate', buttonList, false);
    return () => {
      window.removeEventListener('popstate', buttonList, false);
    };
  });

  const buttonList = () => {
    setIsClick(true);
  };

  const onEnterViewport = trig => {
    if (isClick) return;
    let newLocation = `/periud/${trig.name}`;
    if (location.pathname !== newLocation) {
      history.push(newLocation);
      console.log('newLocation', newLocation);
    }
  };

  const onExitViewport = trig => {
    // console.log('exited', trig);
  };

  const tableIcon = <Icon component={tableSvg} style={{ fontSize: '20px' }} />;
  const graphIcon = <Icon component={graphSvg} style={{ fontSize: '20px' }} />;

  return (
    <InformViewer refCont={InfoRef}>
      {dbData && (
        <div>
          <h1>Выписка за период</h1>
          <section ref={trig[0].ref} className="inform-viewer__section">
            <Waypoint
              onEnter={() => onEnterViewport(trig[0])}
              onLeave={() => onExitViewport(trig[0])}
            />
            <Table
              key="0"
              title="Сводные показатели за период"
              classNames="info-table--summary"
              header={dbData.SummaryData.header}
              body={dbData.SummaryData.body}
              footer={dbData.SummaryData.footer}
            />
          </section>
          <section ref={trig[1].ref} className="inform-viewer__section">
            <Waypoint
              onEnter={() => onEnterViewport(trig[1])}
              onLeave={() => onExitViewport(trig[1])}
            />
            <h2>Текущие инвестиции</h2>
            <Row gutter={24}>
              <Col span={12}>
                <Switch title="Внебиржевой портфель">
                  <SwitchContent id={1} key="1" icon={tableIcon}>
                    <Table
                      header={
                        dbData.CurrentInvestment.LightHeadedBriefcase.header
                      }
                      body={dbData.CurrentInvestment.LightHeadedBriefcase.body}
                      footer={
                        dbData.CurrentInvestment.LightHeadedBriefcase.footer
                      }
                    />
                  </SwitchContent>
                  <SwitchContent id={2} key="2" icon={graphIcon}>
                    <RoundGraph
                      data={dbData.GraphData.LightHeadedBriefcaseRoundGraph}
                    />
                  </SwitchContent>
                </Switch>
              </Col>
              <Col span={12}>
                <Switch title="Облигационный портфель">
                  <SwitchContent id={1} key="1" icon={tableIcon}>
                    <Table
                      header={dbData.CurrentInvestment.BondPortfolio.header}
                      body={dbData.CurrentInvestment.BondPortfolio.body}
                      footer={dbData.CurrentInvestment.BondPortfolio.footer}
                    />
                  </SwitchContent>
                  <SwitchContent id={2} key="2" icon={graphIcon}>
                    <RoundGraph
                      data={dbData.GraphData.BondPortfolioRoundGraph}
                    />
                  </SwitchContent>
                </Switch>
              </Col>
              <Col span={12}>
                <Switch title="Портфель акций">
                  <SwitchContent id={1} key="1" icon={tableIcon}>
                    <Table
                      header={dbData.CurrentInvestment.StockPortfolio.header}
                      body={dbData.CurrentInvestment.StockPortfolio.body}
                      footer={dbData.CurrentInvestment.StockPortfolio.footer}
                    />
                  </SwitchContent>
                  <SwitchContent id={2} key="2" icon={graphIcon}>
                    <RoundGraph
                      data={dbData.GraphData.StockPortfolioRoundGraph}
                      custom="<b>{point.name} (Цена {point.sumdat} р)</b><br>{point.y} р ({point.percentage:.1f} %)"
                    />
                  </SwitchContent>
                </Switch>
              </Col>
              <Col span={12}>
                <Switch title="Валютный и фьючерсный портфель">
                  <SwitchContent id={1} key="1" icon={tableIcon}>
                    <Table
                      header={dbData.CurrentInvestment.FuturesPortfolio.header}
                      body={dbData.CurrentInvestment.FuturesPortfolio.body}
                      footer={dbData.CurrentInvestment.FuturesPortfolio.footer}
                    />
                  </SwitchContent>
                  <SwitchContent id={2} key="2" icon={graphIcon}>
                    <RoundGraph
                      data={dbData.GraphData.FuturesPortfolioRoundGraph}
                      custom="<b>{point.name} (Цена {point.sumdat} р)</b><br>{point.y} шт. ({point.percentage:.1f} %)"
                    />
                  </SwitchContent>
                </Switch>
              </Col>
            </Row>
          </section>
          <section ref={trig[2].ref} className="inform-viewer__section">
            <Waypoint
              onEnter={() => onEnterViewport(trig[2])}
              onLeave={() => onExitViewport(trig[2])}
            />
            <h2>Прибыли за период</h2>
            <Table
              key="5"
              subtitle="Внебиржевой портфель"
              classNames="info-table--summary"
              header={dbData.LightHeadedBriefcase.header}
              body={dbData.LightHeadedBriefcase.body}
              footer={dbData.LightHeadedBriefcase.footer}
            />
            <Table
              key="6"
              subtitle="Облигационный портфель"
              classNames="info-table--summary"
              header={dbData.BondPortfolioKoupon.header}
              body={dbData.BondPortfolioKoupon.body}
              footer={dbData.BondPortfolioKoupon.footer}
            />
            <Table
              key="7"
              subtitle="Портфель акций"
              classNames="info-table--summary"
              header={dbData.StockPortfolio.header}
              body={dbData.StockPortfolio.body}
              footer={dbData.StockPortfolio.footer}
            />
            <Table
              key="8"
              subtitle="Валютные и фьючерсный портфель"
              classNames="info-table--summary"
              header={dbData.FuturesPortfolio.header}
              body={dbData.FuturesPortfolio.body}
              footer={dbData.FuturesPortfolio.footer}
            />
          </section>
          <section ref={trig[3].ref} className="inform-viewer__section">
            <Waypoint
              onEnter={() => onEnterViewport(trig[3])}
              onLeave={() => onExitViewport(trig[3])}
            />
            <h2>Ближайшие выплаты</h2>
            <Row gutter={24}>
              <Col span={12}>
                <h3>Внебиржевой портфель</h3>
                <ChartGraph data={dbData.GraphData.UpcomingPaymentsGraph} />
              </Col>
              <Col span={12}>
                <h3>Купонные выплаты</h3>
                <ChartGraph data={dbData.GraphData.UpcomingKouponGraph} />
              </Col>
            </Row>
            <h3>Купонные выплаты по текущему портфелю</h3>
            <Tabs type="card">
              {dbData.UpcomingPayments.tabs.map((tabItem, key) => {
                const content_name = tabItem.content_table_name;
                const content = dbData.UpcomingPayments.content[content_name];
                return (
                  <TabPane tab={tabItem.value} key={key}>
                    <Table
                      key={`table_${key}`}
                      header={content.header}
                      body={content.body}
                    />
                  </TabPane>
                );
              })}
            </Tabs>
          </section>
        </div>
      )}
    </InformViewer>
  );
};

export default connect(
  ({ page, data }) => ({
    currentPage: page.data.currentPage,
    isClick: page.isClick,
    dbData: data.periudData,
    isLoading: data.isLoading,
  }),
  { ...pageActions, ...dataActions }
)(PeriudExtract);
