import React, { useEffect } from 'react';
// import { useCookies } from 'react-cookie';
import { Route, Redirect, Switch } from 'react-router-dom';

import './App.scss';
import {
  Header,
  Calendar,
  Content,
  Navigation,
  LaneLoading,
} from './components';
import store from './redux/store';
import { userActions } from './redux/actions';

import { QuarterlyStatement, PeriudExtract } from './pages';
import DocumentBtn from './components/DocumentBtn';

const App = () => {
  useEffect(() => {
    store.dispatch(userActions.fetchUserData());
  });

  return (
    <div className="wrapper">
      <LaneLoading procent={50} />
      <div className="size-block">
        <Header />
        <Calendar />
        <DocumentBtn />
      </div>
      <Content>
        <Navigation />
        <Switch>
          <Route
            exact
            path={['/', '/periud/']}
            render={() => <Redirect to="/periud/p1" />}
          />
          <Route path={['/periud/:name']} component={PeriudExtract} />
          <Route path={['/quarterly/:name']} component={QuarterlyStatement} />
        </Switch>
      </Content>
    </div>
  );
};

export default App;
