import React from 'react';
import { connect } from 'react-redux';

import './Content.scss';

const Content = ({ children, isAuth }) => {
  console.log(isAuth);
  if (isAuth === true) return <div className="content">{children}</div>;
  else if (isAuth === false)
    if (process.env.NODE_ENV !== 'development') {
      window.location.href = process.env.REACT_APP_AUTH_URL
        ? process.env.REACT_APP_AUTH_URL
        : `http://auth.mainserver.localdev`;
    } else {
      return <div>Не авторизован</div>;
    }
  else return <div></div>;
};

export default connect(
  ({ user }) => ({
    isAuth: user.isAuth,
  }),
  {}
)(Content);
