import React from 'react';

import './InformViewer.scss';

const InformViewer = ({ children, refCont }) => {
  return (
    <div ref={refCont} className="inform-viewer">
      {children}
    </div>
  );
};

export default InformViewer;
